.dentpin{
    color:black;
    margin-top: 150px;
    margin-right: 100px;
    margin-left: 100px;
}

.shadow1 {
    margin: 40px;
    margin-top: 100px;
    background-color: rgb(68,68,68); /* Needed for IEs */
  
    -moz-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
    -webkit-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
    box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30);
    -ms-filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30)";
    zoom: 1;
  }
  .shadow1 .content {
    position: relative; /* This protects the inner element from being blurred */
    padding: 100px;
    background-color: #DDD;
  }

  @media only screen and (max-width: 414px) {
    .dentpin{
     margin-top: 80px ! important;
     margin-right:10px !important;
     margin-left: 5px !important;
    }
    .box{
      margin-top: 0px !important;
    }
  }