.section-title {
    padding-bottom: 40px;
}

.formlabel{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}