.inbde{
    color:black;
    margin-top: 150px;
    margin-right: 100px;
    margin-left: 100px;
}

@media only screen and (max-width: 414px) {
    .inbde{
     margin-top: 80px ! important;
     margin-right:0px !important;
     margin-left: -40px !important;

    }
    .box{
      margin-top: 0px !important;
    }
  }