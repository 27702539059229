header{
  color:white;
}

.dropdowna{
  padding-left: 0px !important;
  color: black !important;
}

a{
  color: white !important;
}

.togg{
background-color:blue !important;
}

.divcenter {
  padding-left: 50px !important;
}

.ulli{
  padding-left: 20px !important;
}
