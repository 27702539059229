/* #gallery {
    padding: 60px;
    overflow: hidden;
  }
 
p{
    font-family: "Open Sans", sans-serif;
}
  #gallery .owl-nav, #gallery .owl-dots {
    margin-top: 25px;
    text-align: center;
  }
  
  #gallery .owl-item {
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
  }
  
  #gallery .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd;
  }
  
  #gallery .owl-dot.active {
    background-color: #f82249;
  }
  
  #gallery .gallery-carousel .owl-stage-outer {
    overflow: visible;
  }
  
  #gallery .gallery-carousel .center {
    border: 6px solid #f82249;
    margin: -10px;
    box-sizing: content-box;
    padding: 4px;
    background: #fff;
    z-index: 1;
  }

   .wow {
    visibility: hidden;
   }
  .section-header h2 {
  font-size: 36px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
}
.section-header p {
    text-align: center;
    padding: 0;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    margin: 0;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    font-size: 18px;
    font-weight: 500;
    color: #9195a2;
}

 .section-header::before {
    content: '';
    position: absolute;
    display: block;
    width: 60px;
    height: 5px;
    background: #f82249;
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgb(248, 34, 73);
    bottom: 0;
    left: calc(50% - 25px);
} 

.no-js .owl-carousel, .owl-carousel.owl-loaded {
    display: block;
}
.owl-carousel {
    display: none;
    width: 100%;
    z-index: 1;
}
.owl-carousel, .owl-carousel .owl-item {
    -webkit-tap-highlight-color: transparent;
    position: relative;
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
} */

/* img{
  height: 200px;
  width:500px;
  padding: 5px;
} */
#gallery{
  background-color: white !important;
}
img { outline: 0 !important;box-shadow: none; }
img:focus{ outline: 0; box-shadow: none;} 

.section-header h2 {
  font-size: 36px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 30px;
    font-weight: 700;
}

.section-header {
margin-bottom: 1px;
}

#abouts_us{
  color: black !important;
}