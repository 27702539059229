.loginForm{
    background-color: white;
  
}

.gstyle{
    margin-bottom: 40px;
}

.facebook_login{
    font-family: Helvetica,sans-serif;
    /* font-weight: 700; */
    -webkit-font-smoothing: antialiased;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    /* font-size: calc(.27548vw + 12.71074px); */
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .3s,border-color .3s;
    background-color: #4c69ba;
    border: calc(.06887vw + .67769px) solid #4c69ba;
    padding: calc(.34435vw + 5.38843px) calc(.34435vw + 18.38843px);
}