#footer {
    background: #101522;
    padding: 0 0 25px 0;
    color: #eee;
    font-size: 14px;
}

#footer .footer-top {
    background: #040919;
    padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
    margin-bottom: 30px;
}

.footer{
    display: block
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

#footer .footer-top .footer-info img {
    height: 75px;
    margin-bottom: 10px;
    width: 200px;
}
img {
    vertical-align: middle;
    border-style: none;
}

#footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #fff;
}

p {
    padding: 0;
    margin: 0 0 30px 0;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top h4 {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px;
    border-bottom: 2px solid #ff4a17;;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul li {
    border-bottom: 1px solid #262c44;
    padding: 10px 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 5px;
    color:  #ff4a17;;
    font-size: 18px;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#footer .footer-top .footer-links ul a {
    color: #eee;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

#footer .footer-top .footer-contact {
    margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
    line-height: 26px;
}

div {
    display: block;
}

#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #222636;
    color: #eee;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}



/* @media (min-width: 1200px)
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
}

@media (min-width: 992px)
.container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
}
@media (min-width: 768px)
.container, .container-md, .container-sm {
    max-width: 720px;
}
@media (min-width: 576px)
.container, .container-sm {
    max-width: 540px;
}
@media (min-width: 1200px)
.container {
    max-width: 1140px;
}
@media (min-width: 992px)
.container {
    max-width: 960px;
}
@media (min-width: 768px)
.container {
    max-width: 720px;
}
@media (min-width: 576px)
.container {
    max-width: 540px;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
} */