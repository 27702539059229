#faq {
    padding: 60px 0;
    background-color: white;
}

.fadeInUp{
    visibility: visible;
    animation-name: fadeInUp;
}

.section-header {
    margin-bottom: 60px;
    position: relative;
    padding-bottom: 20px;
}

.section-header h2 {
    font-size: 36px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
}

h2{
    line-height: 1.2;
    font-family: "Raleway", sans-serif;
    margin: 0 0 20px 0;
    padding: 0;
    color: #0e1b4d;
}

.justify-content-center {
    -ms-flex-pack: center!important;
    justify-content: center!important;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

#faq #faq-list {
    padding: 0;
    list-style: none;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

#faq #faq-list li {
    border-bottom: 1px solid #ddd;
}

#faq #faq-list a.collapsed {
    color: #000;
}

#faq #faq-list a.collapse {
    color:red ;
}

.acolur{
    color: black !important;
}

#faq #faq-list a{
    padding: 18px 0;
    display: block;
    position: relative;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 1000;
    padding-right: 20px;
}

#faq #faq-list i {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 16px;
}

.div-height{
    height:100px;
}

@media (min-width: 992px){
.col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}
}